import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { FxGuard } from "@coralblack/flax";
import { myApi } from "maven-lib/dist/states/apis/my";
import { sessionSlice, SessionState } from "maven-lib/dist/states/reducers/session";

interface AuthRouteProps extends RouteProps {
  session: SessionState | null;
  authorizedOnly?: boolean;
  denyRedirectTo?: string;
}

export function AuthRoute({ authorizedOnly, denyRedirectTo, session, render, component: Component, ...rest }: AuthRouteProps) {
  const Comp: any = Component; // Hack, Type checking error
  const available =
    (authorizedOnly === true && session && session.token) ||
    (authorizedOnly === false && (!session || !session.token)) ||
    (authorizedOnly === undefined && session && session.token)
      ? true
      : false;

  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={(props) =>
        available ? (
          render ? (
            render(props)
          ) : (
            <>
              {session && session.token && (
                <FxGuard
                  key="static"
                  api={{ ...myApi.getMyProfile }}
                  loading={() => <div className="app-spinner" />}
                  error={(err) => {
                    if (err?.code === "CREDENTIAL-INVALID") {
                      dispatch(sessionSlice.actions.signOut());
                      return <>&nbsp;</>;
                    }

                    return (
                      <>
                        [{err?.code || "UNKNOWN"}] {err?.message || "An error has occurred."}
                      </>
                    );
                  }}
                  render={(resp) => (
                    <>
                      <Comp currentUser={resp?.user} userRoles={resp?.roles} {...props} />
                    </>
                  )}
                />
              )}
              {(!session || !session.token) && <Comp {...props} />}
            </>
          )
        ) : (
          <Redirect to={{ pathname: denyRedirectTo, state: { from: props.location } }} />
        )
      }
    />
  );
}

AuthRoute.defaultProps = {
  denyRedirectTo: "/auth",
};
